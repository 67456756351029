import React, { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../Constants";
import { CircularProgress } from "@material-ui/core";

// sandbox
//const clientid = ("AS4znF601IuGdtq0jqSClV5OFWYIDs5CS5B5XUS_5qUtpSSfrm5CfVsdoKcsnS5iow-s3SOnF9ArmCby");
// production
const clientid =
  "AYIG91UNt92rquHGu3ZKKtYIYgnXyWj9BnAmPow6CHtnovCwscH00SCfyBd-6gN7W0t5PO9rjqPEu5r-";

export default function PayPal(indata) {
  const [loaded, error] = useScript(
    "https://www.paypal.com/sdk/js?client-id=" +
      clientid +
      "&disable-funding=credit,card,sepa,bancontact,ideal,sofort,giropay&currency=EUR"
  );

  const [
    validatePayPalProgressShown,
    setValidatePayPalProgressShown
  ] = useState(false);

  useEffect(() => {
    if (loaded && !error) {
      window.paypal
        .Buttons({
          createOrder: function(data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: indata.price,
                    currency: "EUR",
                    description: indata.description,
                    reference_id: indata.orderid
                  }
                }
              ]
            });
          },
          onApprove: function(data, actions) {
            return actions.order.capture().then(function(details) {
              setValidatePayPalProgressShown(true);
              const form = new FormData();
              form.set("action", "order-validatepaypal");
              form.set("paypalorderid", data.orderID);
              form.set("hash", indata.hash);
              form.set("order", indata.id);

              axios
                .post(config.api + "/api/frontend/action", form, {
                  headers: {
                    "Content-Type": "multipart/form-data"
                  }
                })
                .then(response => {
                  if (response.data.success) {
                    window.location.reload();
                  }
                })
                .catch(error => {
                  console.log(
                    error.response.status +
                      " " +
                      error.response.statusText +
                      " - " +
                      error.response.data.message
                  );
                });
            });
          }
        })
        .render("#paypal-button-container");
    }
  }, [loaded, error, indata]);

  return (
    <React.Fragment>
      {loaded && !error && (
        <React.Fragment>
          {validatePayPalProgressShown ? (
            <CircularProgress color="primary" />
          ) : (
            <div id="paypal-button-container" style={{ width: 60 }}></div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

let cachedScripts = [];
function useScript(src) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false
  });

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false
        });
      } else {
        cachedScripts.push(src);

        // Create script
        let script = document.createElement("script");
        script.src = src;
        script.async = true;

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false
          });
        };

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src);
          if (index >= 0) cachedScripts.splice(index, 1);
          script.remove();

          setState({
            loaded: true,
            error: true
          });
        };

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);

        // Add script to document body
        document.body.appendChild(script);

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener("load", onScriptLoad);
          script.removeEventListener("error", onScriptError);
        };
      }
    },
    [src] // Only re-run effect if script src changes
  );

  return [state.loaded, state.error];
}
