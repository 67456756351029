import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Container, Typography, Paper, Button, Modal } from "@material-ui/core";
import { Header, Footer } from "../various";

const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(5), backgroundColor: "#C43A31" },
  backbtn: {
    marginTop: "40px;"
  },
  imgmodal: {
    width: "80%",
    margin: "50px auto 0px auto",
    padding: "10px 10px 40px 10px",
    textAlign: "center",
    outline: 0
  }
}));

export default function HorseGame() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [modalOpened, setModalOpened] = React.useState(false);
  const [modalImgSrc, setModalImgSrc] = React.useState("");

  const clickImg = evt => {
    setModalImgSrc(evt.target.src);
    setModalOpened(true);
  };
  const closeImgModal = evt => {
    setModalOpened(false);
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Header />
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h1" gutterBottom>
            {t("home.topic.title.pictures")}
          </Typography>

          <div id="imgs">
            <a onClick={clickImg}>
              <img
                src="/images/images/0007.jpg"
                alt=""
                className="imgsi imgsi1"
              />
            </a>
            <a onClick={clickImg}>
              <img
                src="/images/images/albert_dagmar.jpg"
                alt=""
                className="imgsi imgsi2"
              />
            </a>
            <a onClick={clickImg}>
              <img
                src="/images/images/astley.jpg"
                alt=""
                className="imgsi imgsi3"
              />
            </a>
            <a onClick={clickImg}>
              <img
                src="/images/images/D0004.jpg"
                alt=""
                className="imgsi imgsi4"
              />
            </a>
            <a onClick={clickImg}>
              <img
                src="/images/images/F0003bis.jpg"
                alt=""
                className="imgsi imgsi5"
              />
            </a>
            <a onClick={clickImg}>
              <img
                src="/images/images/onderolifant.jpg"
                alt=""
                className="imgsi imgsi6"
              />
            </a>
            <a onClick={clickImg}>
              <img
                src="/images/images/rabbitt.jpg"
                alt=""
                className="imgsi imgsi7"
              />
            </a>
            <a onClick={clickImg}>
              <img
                src="/images/images/speelplaatsen.jpg"
                alt=""
                className="imgsi imgsi8"
              />
            </a>
            <a onClick={clickImg}>
              <img
                src="/images/images/spitfire3.jpg"
                alt=""
                className="imgsi imgsi9"
              />
            </a>
          </div>
          <Link to="/">
            <Button className={classes.backbtn} variant="contained">
              &larr; {t("global.goback")}
            </Button>
          </Link>
        </Paper>
      </Container>
      <Modal open={modalOpened} onClose={closeImgModal}>
        <Paper className={classes.imgmodal}>
          <div style={{ textAlign: "right" }}>
            <a
              onClick={closeImgModal}
              style={{
                fontSize: "1.9em",
                marginRight: "25px",
                cursor: "pointer"
              }}
            >
              &times;
            </a>
          </div>
          <img
            src={modalImgSrc}
            alt=""
            className="imgsi"
            style={{ maxWidth: "100%" }}
          />
        </Paper>
      </Modal>
      <Footer />
    </React.Fragment>
  );
}
