import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  TextField,
  Select,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Divider,
  Button,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  IconButton
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";

import axios from "axios";
import { config } from "../../Constants";
import { Header, Footer } from "../various";
import Countries from "../../countries.json";
import Bpost from "../../bpost.json";

const useStyles = makeStyles(theme => ({
  orderform: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1)
  },
  indentedFormControl: {
    marginLeft: theme.spacing(4)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  paper: { padding: theme.spacing(5) },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

export default function OrderTheBook() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState("");
  const [orderName, setOrderName] = useState("");
  const [orderStreetNr, setOrderStreetNr] = useState("");
  const [orderPostalCode, setOrderPostalCode] = useState("");
  const [orderCity, setOrderCity] = useState("");
  const [orderState, setOrderState] = useState("");
  const [orderCountry, setOrderCountry] = useState("");
  const [orderEmail, setOrderEmail] = useState("");
  const [orderPhonenumber, setOrderPhonenumber] = useState("");
  const [orderAmountEN, setOrderAmountEN] = useState(
    i18n.language.split("-")[0] === "en" ? 1 : 0
  );
  const [orderAmountNL, setOrderAmountNL] = useState(
    i18n.language.split("-")[0] === "nl" ? 1 : 0
  );
  const [orderAmountFR, setOrderAmountFR] = useState(
    i18n.language.split("-")[0] === "fr" ? 1 : 0
  );
  const [orderShipOrPickup, setOrderShipOrPickup] = useState("shiptracked");
  const [orderPaymentMethod, setOrderPaymentMethod] = useState("banktransfer");
  const [
    orderPaymentCreditCardDisabled,
    setOrderPaymentCreditCardDisabled
  ] = useState(false);
  const [
    orderPaymentBankTransferDisabled,
    setOrderPaymentBankTransferDisabled
  ] = useState(false);
  const [orderPaymentCashDisabled, setOrderPaymentCashDisabled] = useState(
    true
  );
  const [orderTotalPrice, setOrderTotalPrice] = useState(0);
  const [orderTotalAmountOfBooks, setOrderTotalAmountOfBooks] = useState(0);
  const [orderTotalWeight, setOrderTotalWeight] = useState(0);
  const [orderTotalShippingCost, setOrderTotalShippingCost] = useState(0);

  const [weightErrorButtonShown, setWeightErrorButtonShown] = useState(false);
  const [placeOrderButtonShown, setPlaceOrderButtonShown] = useState(true);
  const [placeOrderButtonDisabled, setPlaceOrderButtonDisabled] = useState(
    false
  );
  const [placeOrderProgressShown, setPlaceOrderProgressShown] = useState(false);

  const [isErrorNameField, setIsErrorNameField] = useState(false);
  const [isErrorStreetNrField, setIsErrorStreetNrField] = useState(false);
  const [isErrorPostalCodeField, setIsErrorPostalCodeField] = useState(false);
  const [isErrorCityField, setIsErrorCityField] = useState(false);
  const [isErrorCountryField, setIsErrorCountryField] = useState(false);
  const [isErrorEmailField, setIsErrorEmailField] = useState(false);
  const [isErrorPhonenumberField, setIsErrorPhonenumberField] = useState(false);

  const updateShipOrPickup = evt => {
    let newValue = evt.target.value;
    setOrderShipOrPickup(newValue);
    if (newValue === "ship" || newValue === "shiptracked") {
      setOrderPaymentCreditCardDisabled(false);
      setOrderPaymentBankTransferDisabled(false);
      setOrderPaymentCashDisabled(true);
      if (orderPaymentMethod === "cash") {
        setOrderPaymentMethod("banktransfer");
      }
    } else {
      setOrderPaymentCreditCardDisabled(true);
      setOrderPaymentBankTransferDisabled(true);
      setOrderPaymentCashDisabled(false);
      setOrderPaymentMethod("cash");
    }
    calculateTotalPrice();
  };

  const calculateTotalPrice = () => {
    // determine amount of books ordered
    let amountEN =
      isNaN(orderAmountEN) || orderAmountEN === ""
        ? 0
        : parseInt(orderAmountEN);
    let amountNL =
      isNaN(orderAmountNL) || orderAmountNL === ""
        ? 0
        : parseInt(orderAmountNL);
    let amountFR =
      isNaN(orderAmountFR) || orderAmountFR === ""
        ? 0
        : parseInt(orderAmountFR);

    let totalAmountOfBooks = amountEN + amountNL + amountFR;
    setOrderTotalAmountOfBooks(totalAmountOfBooks);

    // determine total weight
    let totalWeight = totalAmountOfBooks * Bpost.book.weight;
    setOrderTotalWeight(totalWeight);

    if (totalWeight > 30000 || (totalWeight > 20000 && orderCountry == "NL")) {
      setPlaceOrderButtonShown(false);
      setWeightErrorButtonShown(true);
    } else {
      setPlaceOrderButtonShown(true);
      setWeightErrorButtonShown(false);
    }

    setPlaceOrderButtonShown(totalAmountOfBooks > 0 && orderCountry !== "");

    // determine shipping cost
    let shippingCost = 0;
    if (orderCountry === "BE") {
      shippingCost = determineCostByWeight(totalWeight, Bpost.shippingcost.BE);
    } else if (orderCountry === "NL") {
      shippingCost = determineCostByWeight(totalWeight, Bpost.shippingcost.NL);
    } else {
      let countryZone = determineCountryZone(
        Bpost.countryzones,
        orderCountry,
        totalWeight
      );
      shippingCost = determineShippingCost(
        totalWeight,
        countryZone,
        Bpost.shippingcost
      );
    }

    // determine tracking cost
    // disabled -> bpack world contains tracking
    // if (orderShipOrPickup === "shiptracked") {
    //   if (orderCountry !== "BE") {
    //     shippingCost += Bpost.trackingcost;
    //   }
    // }

    // no shipping costs in case of manual pickup
    if (orderShipOrPickup === "pickup") {
      shippingCost = 0;
    }

    setOrderTotalShippingCost(shippingCost);

    // determine total price
    let totalPrice = (amountEN + amountNL + amountFR) * Bpost.book.unitPrice;
    if (orderShipOrPickup === "ship" || orderShipOrPickup === "shiptracked") {
      totalPrice += shippingCost;
    }

    setOrderTotalPrice(totalPrice);
    return totalPrice;
  };

  const determineCostByWeight = (weight, costGrid) => {
    for (var cg of costGrid) {
      if (weight <= cg.weight) {
        return cg.price;
      }
    }
  };

  const determineCountryZone = (allCountryZones, countryCode, totalWeight) => {
    let zoneList = allCountryZones.WorldLight;
    if (totalWeight > 2) {
      zoneList = allCountryZones.World;
    }
    // force to bpack World, don't use WorldLight
    zoneList = allCountryZones.World;

    for (var zone of zoneList) {
      if (zone.countries.includes(countryCode)) {
        return zone.zone;
      }
    }
    return 4; // default to "all other countries zone"
  };

  const determineShippingCost = (totalWeight, countryZone, shippingCosts) => {
    let costGrid = shippingCosts.WorldLight;
    if (totalWeight > 2000) {
      costGrid = shippingCosts.World;
    }
    // force to bpack World, don't use WorldLight
    costGrid = shippingCosts.World;

    for (var sc of costGrid) {
      if (sc.zone === countryZone) {
        for (var pg of sc.cost) {
          if (totalWeight <= pg.weight) {
            return pg.price;
          }
        }
      }
    }
  };

  const placeOrder = () => {
    let encounteredError = false;
    setIsErrorNameField(false);
    setIsErrorStreetNrField(false);
    setIsErrorPostalCodeField(false);
    setIsErrorCityField(false);
    setIsErrorCountryField(false);
    setIsErrorEmailField(false);
    setIsErrorPhonenumberField(false);

    if (orderName.trim().length === 0) {
      setIsErrorNameField(true);
      encounteredError = true;
    }
    if (orderStreetNr.trim().length === 0) {
      setIsErrorStreetNrField(true);
      encounteredError = true;
    }
    if (orderPostalCode.trim().length === 0) {
      setIsErrorPostalCodeField(true);
      encounteredError = true;
    }
    if (orderCity.trim().length === 0) {
      setIsErrorCityField(true);
      encounteredError = true;
    }
    if (orderCountry.length === 0) {
      setIsErrorCountryField(true);
      encounteredError = true;
    }
    if (
      orderEmail.trim().length === 0 ||
      orderEmail.indexOf(".") === -1 ||
      orderEmail.indexOf("@") === -1
    ) {
      setIsErrorEmailField(true);
      encounteredError = true;
    }
    if (
      (orderShipOrPickup === "ship" || orderShipOrPickup === "shiptracked") &&
      orderPhonenumber.trim().length === 0
    ) {
      setIsErrorPhonenumberField(true);
      encounteredError = true;
    }

    if (!encounteredError) {
      setPlaceOrderButtonDisabled(true);
      setPlaceOrderProgressShown(true);

      const form = new FormData();
      form.set("action", "order-register");
      form.set("name", orderName);
      form.set("streetnr", orderStreetNr);
      form.set("postalcode", orderPostalCode);
      form.set("city", orderCity);
      form.set("state", orderState);
      form.set("country", orderCountry);
      form.set("email", orderEmail);
      form.set("phonenumber", orderPhonenumber);
      form.set("amounten", orderAmountEN);
      form.set("amountnl", orderAmountNL);
      form.set("amountfr", orderAmountFR);
      form.set("shiporpickup", orderShipOrPickup);
      form.set("paymentmethod", orderPaymentMethod);
      form.set("weight", "" + orderTotalWeight);
      form.set("shippingcost", "" + orderTotalShippingCost);
      form.set("totalprice", "" + orderTotalPrice);
      form.set("language", i18n.language.split("-")[0]);

      axios
        .post(config.api + "/api/frontend/action", form, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.success) {
            let url = "/order/" + response.data.hash + "/" + response.data.id;
            history.push(url);
          } else {
            setErrorSnackBarMessage(response.data.message);
            setErrorSnackBarOpen(true);
          }
        })
        .catch(error => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrorSnackBarMessage(error.response.data.message);
          } else {
            setErrorSnackBarMessage(
              error.response.status + " " + error.response.statusText
            );
          }
          setErrorSnackBarOpen(true);
        });
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    calculateTotalPrice();
  });

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Header />
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h1" gutterBottom>
            {t("home.topic.title.orderbook")}
          </Typography>
          <Typography gutterBottom>{t("orderbook.explanation")}</Typography>
          <Grid container>
            <Grid item xs={6}>
              <form className={classes.orderform}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      id="order-name"
                      name="order-name"
                      value={orderName}
                      onChange={evt => {
                        setOrderName(evt.target.value);
                      }}
                      error={isErrorNameField}
                      className={classes.textField}
                      label={t("orderbook.name")}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="order-streetnr"
                      name="order-streetnr"
                      value={orderStreetNr}
                      onChange={evt => {
                        setOrderStreetNr(evt.target.value);
                      }}
                      error={isErrorStreetNrField}
                      className={classes.textField}
                      label={t("orderbook.streetnr")}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="order-postalcode"
                      name="order-postalcode"
                      value={orderPostalCode}
                      onChange={evt => {
                        setOrderPostalCode(evt.target.value);
                      }}
                      error={isErrorPostalCodeField}
                      className={classes.textField}
                      label={t("orderbook.postalcode")}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      id="order-city"
                      name="order-city"
                      value={orderCity}
                      onChange={evt => {
                        setOrderCity(evt.target.value);
                      }}
                      error={isErrorCityField}
                      className={classes.textField}
                      label={t("orderbook.city")}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="order-state"
                      name="order-state"
                      value={orderState}
                      onChange={evt => {
                        setOrderState(evt.target.value);
                      }}
                      className={classes.textField}
                      label={t("orderbook.state")}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                    >
                      <InputLabel id="order-country-inputlabel">
                        {t("orderbook.country")}
                      </InputLabel>
                      <Select
                        labelId="order-country-inputlabel"
                        id="order-country"
                        error={isErrorCountryField}
                        value={orderCountry}
                        onChange={evt => {
                          setOrderCountry(evt.target.value);
                        }}
                      >
                        {Countries.map(country => {
                          return (
                            <MenuItem key={country.Code} value={country.Code}>
                              {country.Name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="order-email"
                      name="order-email"
                      value={orderEmail}
                      onChange={evt => {
                        setOrderEmail(evt.target.value);
                      }}
                      error={isErrorEmailField}
                      className={classes.textField}
                      label={t("orderbook.email")}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="order-phonenumber"
                      name="order-phonenumber"
                      value={orderPhonenumber}
                      helperText={t("orderbook.phonenumberinfo")}
                      onChange={evt => {
                        setOrderPhonenumber(evt.target.value);
                      }}
                      error={isErrorPhonenumberField}
                      className={classes.textField}
                      label={t("orderbook.phonenumber")}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="order-amounten"
                      name="order-amounten"
                      value={orderAmountEN}
                      onChange={evt => {
                        setOrderAmountEN(evt.target.value);
                      }}
                      type="number"
                      inputProps={{ min: "0" }}
                      label={
                        t("orderbook.amountofbooks") +
                        " " +
                        t("orderbook.englishversion")
                      }
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="order-amountnl"
                      name="order-amountnl"
                      value={orderAmountNL}
                      onChange={evt => {
                        setOrderAmountNL(evt.target.value);
                      }}
                      type="number"
                      inputProps={{ min: "0" }}
                      label={
                        t("orderbook.amountofbooks") +
                        " " +
                        t("orderbook.dutchversion")
                      }
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="order-amountfr"
                      name="order-amountfr"
                      value={orderAmountFR}
                      onChange={evt => {
                        setOrderAmountFR(evt.target.value);
                      }}
                      type="number"
                      inputProps={{ min: "0" }}
                      label={
                        t("orderbook.amountofbooks") +
                        " " +
                        t("orderbook.frenchversion")
                      }
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.indentedFormControl}
                    >
                      <RadioGroup
                        name="order-shiporpickup"
                        value={orderShipOrPickup}
                        onChange={updateShipOrPickup}
                      >
                        {/* <FormControlLabel
                          value="ship"
                          control={<Radio />}
                          label={t("orderbook.shippingthroughpost")}
                        /> */}
                        <FormControlLabel
                          value="shiptracked"
                          control={<Radio />}
                          label={t("orderbook.shippingthroughpostwithtracking")}
                        />
                        <FormControlLabel
                          value="pickup"
                          control={<Radio />}
                          label={t("orderbook.pickupatauthor")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.indentedFormControl}
                    >
                      <RadioGroup
                        name="order-paymentmethod"
                        value={orderPaymentMethod}
                        onChange={evt => {
                          setOrderPaymentMethod(evt.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="creditcard"
                          control={<Radio />}
                          label={t("orderbook.paywithcreditcard")}
                          disabled={orderPaymentCreditCardDisabled}
                        />
                        <FormControlLabel
                          value="banktransfer"
                          control={<Radio />}
                          label={t("orderbook.paywithbanktransfer")}
                          disabled={orderPaymentBankTransferDisabled}
                        />
                        <FormControlLabel
                          value="cash"
                          control={<Radio />}
                          label={t("orderbook.paywithcash")}
                          disabled={orderPaymentCashDisabled}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography paragraph={true}>
                      {t("orderbook.totalamountofbooks")}:{" "}
                      {orderTotalAmountOfBooks} x{" "}
                      {(Bpost.book.unitPrice / 100).toFixed(2)} EUR
                    </Typography>
                    <Typography paragraph={true}>
                      {t("orderbook.totalweight")}:{" "}
                      {(orderTotalWeight / 1000).toFixed(3)} kg
                    </Typography>
                    {placeOrderButtonShown ? (
                      <React.Fragment>
                        <Typography paragraph={true}>
                          {t("orderbook.shippingcost")}:{" "}
                          {(orderTotalShippingCost / 100).toFixed(2)} EUR
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                          {t("orderbook.totalprice")}:{" "}
                          {(orderTotalPrice / 100).toFixed(2)} EUR
                        </Typography>
                      </React.Fragment>
                    ) : null}

                    {weightErrorButtonShown ? (
                      <a href="mailto:info@circuskean.com">
                        <Button variant="contained" color="secondary">
                          {t("orderbook.weighterror")}
                        </Button>
                      </a>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid item xs={12} align="center">
                    {placeOrderButtonShown ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={placeOrder}
                        disabled={placeOrderButtonDisabled}
                      >
                        {t("orderbook.placeorder")}
                      </Button>
                    ) : null}
                  </Grid>
                  {placeOrderProgressShown ? (
                    <React.Fragment>
                      <Grid item xs={12} align="center">
                        <CircularProgress color="primary" />
                      </Grid>
                    </React.Fragment>
                  ) : null}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorSnackBarOpen}
        onClose={e => {
          setErrorSnackBarOpen(false);
        }}
      >
        <SnackbarContent
          className={classes.error}
          onClose={e => {
            setErrorSnackBarOpen(false);
          }}
          aria-describedby="errorsb"
          message={
            <span id="errorsb" className={classes.message}>
              <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
              {errorSnackBarMessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={e => {
                setErrorSnackBarOpen(false);
              }}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    </React.Fragment>
  );
}
