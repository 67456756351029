const production = {
  api: "https://circuskean.com/"
};

const development = {
  api: "https://circuskean.local.zappatic.net/"
};

export const config =
  process.env.NODE_ENV === "development" ? development : production;
