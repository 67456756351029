import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Paper } from "@material-ui/core";
import { Header, Footer } from "../various";

const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(5) }
}));

export default function OrderTheBook() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const slideImages = [];
  slideImages.push(
    "/sample/circuskean_" + i18n.language.split("-")[0] + "_pcover.jpg"
  );
  for (const x of Array(7).keys()) {
    slideImages.push(
      "/sample/circuskean_" + i18n.language.split("-")[0] + "_p000" + x + ".jpg"
    );
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Header />
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h1" gutterBottom>
            {t("home.aboutbook.takealook")}
          </Typography>
          <Typography gutterBottom>{t("browsebook.intro")}</Typography>
        </Paper>

        <div style={{ backgroundColor: "#C43A31", padding: "20px 0 40px 0" }}>
          {slideImages.map(slimg => {
            return (
              <Paper
                style={{
                  width: 600,
                  margin: "0 auto",
                  marginTop: 20,
                  padding: 10,
                  textAlign: "center"
                }}
              >
                <img src={slimg} style={{ width: 550 }} alt="" />
              </Paper>
            );
          })}
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
