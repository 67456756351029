import React, { useState } from "react";
import axios from "axios";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Header, Footer } from "../various";
import {
  Container,
  TextField,
  Typography,
  Grid,
  Paper,
  Button,
  Snackbar,
  SnackbarContent,
  IconButton
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  preorderform: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  btn: {
    marginLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  paper: { padding: theme.spacing(5) },
  success: {
    backgroundColor: green[600]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

export default function PreorderTheBook() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [preorderEmail, setPreorderEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailInvalidHelperText, setEmailInvalidHelperText] = useState("");
  const [successSBOpen, setSuccessSBOpen] = useState(false);

  const submitFrm = evt => {
    evt.preventDefault();
    if (!preorderEmail.includes(".") || !preorderEmail.includes("@")) {
      setEmailInvalid(true);
      setEmailInvalidHelperText(t("error.incorrectemail"));
      return;
    }
    setEmailInvalid(false);
    setEmailInvalidHelperText("");

    const form = new FormData();
    form.set("action", "preorder-register");
    form.set("email", preorderEmail);
    form.set("language", i18n.language.split("-")[0]);
    axios
      .post("/api/frontend/action", form, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response.data.success) {
          setPreorderEmail("");
          setSuccessSBOpen(true);
        }
      });
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Header />
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h1" gutterBottom>
            {t("preorderbook.title")}
          </Typography>
          <Typography gutterBottom>{t("preorderbook.p1")}</Typography>
          <Grid container>
            <Grid item xs={6}>
              <form className={classes.preorderform} onSubmit={submitFrm}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={emailInvalid}
                      helperText={emailInvalidHelperText}
                      id="preorder-email"
                      value={preorderEmail}
                      onChange={evt => {
                        setPreorderEmail(evt.target.value);
                      }}
                      className={classes.textField}
                      label={t("orderbook.email")}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                    >
                      {t("preorderbook.title")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={successSBOpen}
        autoHideDuration={4000}
        onClose={e => {
          setSuccessSBOpen(false);
        }}
      >
        <SnackbarContent
          className={classes.success}
          onClose={e => {
            setSuccessSBOpen(false);
          }}
          aria-describedby="successsb"
          variant="success"
          message={
            <span id="successsb" className={classes.message}>
              <CheckCircleIcon
                className={clsx(classes.icon, classes.iconVariant)}
              />
              {t("preorderbook.successmsg")}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={e => {
                setSuccessSBOpen(false);
              }}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    </React.Fragment>
  );
}
