import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Header, Footer } from "../various";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  topicCard: {
    display: "flex"
  },
  topicCardDetails: {
    flex: 1
  },
  topicCardMedia: {
    width: 160
  },
  centerCard: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  centerCardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  centerCardContent: {
    flexGrow: 1
  },
  aboutCard: {
    display: "flex"
  },

  aboutCardMedia: {
    width: 400
  },
  aboutCardDetails: {
    flex: 1
  },
  talbtn: {
    marginTop: "20px;"
  }
}));

const topTopicIDs = ["locations", "horsegame", "history", "links"];
const centerTopicIDs = ["forum", "pictures", "orderbook"];
const bottomTopicIDs = ["culturalheritage", "war", "itegem", "greatbritain"];

const generateTopic = (topicID, t, i18n) => {
  let topic = {};
  topic.id = topicID;
  topic.title = t("home.topic.title." + topicID);
  topic.body = t("home.topic.body." + topicID);
  topic.img = t("home.topic.img." + topicID);
  let href = t("home.topic.href." + topicID);
  if (!href.startsWith("http")) {
    href = "/" + i18n.language.split("-")[0] + href;
  }
  topic.link = href;
  return topic;
};

export default function Home() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  let topTopics = topTopicIDs.map(topicID => {
    return generateTopic(topicID, t, i18n);
  });
  let centerTopics = centerTopicIDs.map(topicID => {
    return generateTopic(topicID, t, i18n);
  });
  let bottomTopics = bottomTopicIDs.map(topicID => {
    return generateTopic(topicID, t, i18n);
  });

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Header />

        <Card className={classes.aboutCard}>
          <CardContent className={classes.aboutCardDetails}>
            <Typography gutterBottom component="h1" variant="h4">
              {t("home.aboutbook")}
            </Typography>
            {t("home.aboutbooktext1")
              .split("\n")
              .map((text, i) => {
                return (
                  <Typography gutterBottom key={i}>
                    {text}
                  </Typography>
                );
              })}
            <Typography>
              <Link to={t("home.aboutbook.takealook.href")}>
                <Button variant="contained" className={classes.talbtn}>
                  {t("home.aboutbook.takealook")}
                </Button>
              </Link>
            </Typography>
          </CardContent>
          <Hidden xsDown>
            <CardMedia
              className={classes.aboutCardMedia}
              image="/images/home/about-the-book.jpg"
              title={t("home.aboutbook")}
            />
          </Hidden>
        </Card>

        <Box mt={8}>
          <Grid container spacing={4}>
            {topTopics.map(topic => (
              <Grid item key={topic.id} xs={12} md={6}>
                <CardActionArea component="div">
                  <Link to={topic.link}>
                    <Card className={classes.topicCard}>
                      <div className={classes.topicCardDetails}>
                        <CardContent>
                          <Typography component="h2" variant="h5" gutterBottom>
                            {topic.title}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                            {topic.body}
                          </Typography>
                          <Typography variant="subtitle1" color="primary">
                            {t("global.readmore")}
                          </Typography>
                        </CardContent>
                      </div>
                      <Hidden xsDown>
                        <CardMedia
                          className={classes.topicCardMedia}
                          image={topic.img}
                          title={topic.title}
                        />
                      </Hidden>
                    </Card>
                  </Link>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={8}>
          <Grid container spacing={4}>
            {centerTopics.map(topic => (
              <Grid item key={topic.id} xs={12} sm={6} md={4}>
                <CardActionArea component="div">
                  <Link to={topic.link}>
                    <Card className={classes.centerCard}>
                      <Hidden xsDown>
                        <CardMedia
                          className={classes.centerCardMedia}
                          image={topic.img}
                          title={topic.title}
                        />
                      </Hidden>
                      <CardContent className={classes.centerCardContent}>
                        <Typography component="h2" variant="h5" gutterBottom>
                          {topic.title}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                          {topic.body}
                        </Typography>
                        <Typography variant="subtitle1" color="primary">
                          {t("global.readmore")}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={8}>
          <Grid container spacing={4}>
            {bottomTopics.map(topic => (
              <Grid item key={topic.id} xs={12} md={6}>
                <CardActionArea component="div">
                  <Link to={topic.link}>
                    <Card className={classes.topicCard}>
                      <div className={classes.topicCardDetails}>
                        <CardContent>
                          <Typography component="h2" variant="h5" gutterBottom>
                            {topic.title}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                            {topic.body}
                          </Typography>
                          <Typography variant="subtitle1" color="primary">
                            {t("global.readmore")}
                          </Typography>
                        </CardContent>
                      </div>
                      <Hidden xsDown>
                        <CardMedia
                          className={classes.topicCardMedia}
                          image={topic.img}
                          title={topic.title}
                        />
                      </Hidden>
                    </Card>
                  </Link>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
