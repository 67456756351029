import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: "#C43A31",
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0)
  }
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography variant="body2" align="center" style={{ color: "white" }}>
          © 2019 - Frans Nauwelaerts |{" "}
          <a style={{ color: "white" }} href="mailto:info@circuskean.com">
            info@circuskean.com
          </a>{" "}
          |{" "}
          <Link style={{ color: "white" }} to="/privacypolicy">
            Privacy policy
          </Link>
        </Typography>
      </Container>
    </footer>
  );
}
