import React from "react";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation, withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  mainHeader: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(/images/header.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)"
  },
  mainHeaderContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  },
  headertext: {
    textShadow: "1px 1px 2px #555",
    fontFamily: '"Arvo", sans-serif'
  },
  plainlink: {
    color: "#fff"
  },
  pointyCursor: { cursor: "pointer" },
  langTxt: { color: "#C43A31", fontWeight: "bold" },
  langSepTxt: { color: "white", fontWeight: "bold" }
}));

function LanguageSwitcherComp(props) {
  const { i18n, t, history, match, classes } = props;
  const changeLanguage = nextLang => {
    i18n.changeLanguage(nextLang, () => {
      const newUrl = `/${nextLang}/${t(match.params.page)}`;
      history.push(newUrl);
    });
  };
  return (
    <React.Fragment>
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          <Typography
            onClick={() => changeLanguage("nl")}
            className={clsx(classes.pointyCursor, classes.langTxt)}
          >
            Nederlands
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.langSepTxt}> | </Typography>
        </Grid>
        <Grid item>
          <Typography
            onClick={() => changeLanguage("en")}
            className={clsx(classes.pointyCursor, classes.langTxt)}
          >
            English
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.langSepTxt}> | </Typography>
        </Grid>
        <Grid item>
          <Typography
            onClick={() => changeLanguage("fr")}
            className={clsx(classes.pointyCursor, classes.langTxt)}
          >
            Français
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const LanguageSwitcher = withRouter(
  withTranslation("translations")(LanguageSwitcherComp)
);

export default function Header() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Toolbar>
        <LanguageSwitcher classes={classes} />
      </Toolbar>
      <Box mt={-2}>
        <Paper className={classes.mainHeader}>
          <img
            style={{ display: "none" }}
            src="/images/header.jpg"
            alt="background"
          />
          <div className={classes.overlay} />
          <Grid container>
            <Grid item md={6}>
              <div className={classes.mainHeaderContent}>
                <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  className={classes.headertext}
                >
                  <Link to="/" className={classes.plainlink}>
                    {t("header.title")}
                  </Link>
                </Typography>

                <Typography
                  variant="h5"
                  color="inherit"
                  gutterBottom
                  className={classes.headertext}
                >
                  <Link to="/" className={classes.plainlink}>
                    {t("header.subtitle")}
                  </Link>
                </Typography>
                <Box mt={5}>
                  <Link to={t("home.topic.href.orderbook")}>
                    <Button variant="contained">
                      {t("header.buythebook")}
                    </Button>
                  </Link>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
}
