import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Container, Typography, Paper, Snackbar, SnackbarContent, IconButton, CircularProgress, Box, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Header, Footer } from "../various";
import PayPal from "../various/PayPal.jsx";
import { config } from "../../Constants";
import Countries from "../../countries.json";

const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(5) },
  backbtn: {
    marginTop: "40px;"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  table: {
    maxWidth: 650,
    marginBottom: 25
  },
  statuslistroot: {
    width: "100%",
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper
  },
  green: { color: "green" },
  lightgray: { color: "lightgray" }
}));

export default function Order() {
  const classes = useStyles();
  const { t } = useTranslation();
  let { orderid, orderhash } = useParams();

  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState("");
  const [fetchOrderProgressShown, setFetchOrderProgressShown] = useState(true);
  const [contentShown, setContentShown] = useState(false);
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    axios
      .get(config.api + "/api/frontend/action", {
        params: {
          action: "order-details",
          order: orderid,
          hash: orderhash
        }
      })
      .then(response => {
        if (response.data.success) {
          let foundCountryName = Countries.filter(country => country.Code === response.data.country)[0].Name;
          response.data.countryName = foundCountryName;

          if (response.data.shiporpickup === "ship") {
            response.data.shippingmethod = t("orderbook.shippingthroughpost");
          } else if (response.data.shiporpickup === "shiptracked") {
            response.data.shippingmethod = t("orderbook.shippingthroughpostwithtracking");
          } else if (response.data.shiporpickup === "pickup") {
            response.data.shippingmethod = t("orderbook.pickupatauthor");
          }
          if (response.data.paymentmethod === "creditcard") {
            response.data.paymentmethodtext = t("orderbook.paywithcreditcard");
          } else if (response.data.paymentmethod === "banktransfer") {
            response.data.paymentmethodtext = t("orderbook.paywithbanktransfer");
          } else if (response.data.paymentmethod === "cash") {
            response.data.paymentmethodtext = t("orderbook.paywithcash");
          }

          response.data.paymentreceived = response.data.paid ? t("global.yes") : t("global.no");

          if (response.data.paymentmethod === "banktransfer") {
            response.data.paymentinstructions = (
              <React.Fragment>
                <Typography gutterBottom>{t("order.banktransferinfo1")}</Typography>
                <Box ml={5} mt={2}>
                  <Typography style={{ fontWeight: 500 }}>{t("order.banktransferinfo2")}</Typography>
                  <Typography style={{ fontWeight: 500 }}>{t("order.banktransferinfo3")}</Typography>
                  <Typography style={{ fontWeight: 500 }}>{t("order.banktransferinfo4")}</Typography>
                  <Typography style={{ fontWeight: 500 }}>
                    {t("global.amount")}: {response.data.totalprice} EUR
                  </Typography>
                  <Typography style={{ fontWeight: 500 }}>
                    {t("order.banktransfermessage")}: {response.data.orderid}
                  </Typography>
                </Box>
              </React.Fragment>
            );
          } else if (response.data.paymentmethod === "cash") {
            response.data.paymentinstructions = (
              <React.Fragment>
                <Typography gutterBottom>{t("order.pickupinfo")}</Typography>
                <Box ml={5} mt={2}>
                  <Typography style={{ fontWeight: 500 }}>Stationsstraat 72/74</Typography>
                  <Typography style={{ fontWeight: 500 }}>2220 Heist-op-den-Berg</Typography>
                  <Typography style={{ fontWeight: 500 }}>België</Typography>
                </Box>
              </React.Fragment>
            );
          } else if (response.data.paymentmethod === "creditcard") {
            response.data.paymentinstructions = (
              <React.Fragment>
                <Typography gutterBottom>{t("order.creditcardinfo")}</Typography>
                <Box ml={5} mt={2}>
                  <PayPal
                    price={response.data.totalprice}
                    description={response.data.orderid + " " + t("header.title") + " " + t("header.subtitle")}
                    orderid={response.data.orderid}
                    hash={orderhash}
                    id={orderid}
                  />
                </Box>
              </React.Fragment>
            );
          }

          if (response.data.trackingnumber) {
            if (response.data.shipped_by === "bpost") {
              response.data.trackinglink =
                "http://track.bpost.be/btr/web/#/search?itemCode=" + response.data.trackingnumber + "&postalCode=" + response.data.postalcode + "&lang=" + response.data.language;
            } else if (response.data.shipped_by === "postnl") {
              response.data.trackinglink = "https://jouw.postnl.nl/#!/track-en-trace/" + response.data.trackingnumber + "/" + response.data.country + "/" + response.data.postalcode;
            }
          }
          setOrderData(response.data);
          setContentShown(true);
        } else {
          setErrorSnackBarMessage(response.data.message);
          setErrorSnackBarOpen(true);
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.message) {
            setErrorSnackBarMessage(error.response.data.message);
          } else {
            setErrorSnackBarMessage(error.response.status + " " + error.response.statusText);
          }
        }

        setErrorSnackBarOpen(true);
      });
    setFetchOrderProgressShown(false);
  }, [orderid, orderhash, t]);

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Header />
        <Paper className={classes.paper}>
          {fetchOrderProgressShown ? <CircularProgress color="primary" /> : null}

          {contentShown ? (
            <React.Fragment>
              <Typography variant="h4" component="h1" gutterBottom>
                {t("order.yourorder")} #{orderData.orderid}
              </Typography>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("global.date")}
                    </TableCell>
                    <TableCell>{orderData.date}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.name")}
                    </TableCell>
                    <TableCell>{orderData.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.streetnr")}
                    </TableCell>
                    <TableCell>{orderData.streetnr}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.city")}
                    </TableCell>
                    <TableCell>
                      {orderData.postalcode} {orderData.city} {orderData.state}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.country")}
                    </TableCell>
                    <TableCell>{orderData.countryName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.email")}
                    </TableCell>
                    <TableCell>{orderData.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.phonenumber")}
                    </TableCell>
                    <TableCell>{orderData.phonenumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.amountofbooks")} {t("orderbook.englishversion")}
                    </TableCell>
                    <TableCell>{orderData.amounten}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.amountofbooks")} {t("orderbook.dutchversion")}
                    </TableCell>
                    <TableCell>{orderData.amountnl}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.amountofbooks")} {t("orderbook.frenchversion")}
                    </TableCell>
                    <TableCell>{orderData.amountfr}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.shippingorpickup")}
                    </TableCell>
                    <TableCell>{orderData.shippingmethod}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.paymentmethod")}
                    </TableCell>
                    <TableCell>{orderData.paymentmethodtext}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.totalweight")}
                    </TableCell>
                    <TableCell>{orderData.weight} kg</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.shippingcost")}
                    </TableCell>
                    <TableCell>{orderData.shippingcost} EUR</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("orderbook.totalprice")}
                    </TableCell>
                    <TableCell>{orderData.totalprice} EUR</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("order.paymentreceived")}
                    </TableCell>
                    <TableCell>{orderData.paymentreceived}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {!orderData.paid ? (
                <React.Fragment>
                  <Typography variant="h4" component="h1" gutterBottom>
                    {t("order.paymentinstructions")}
                  </Typography>
                  {orderData.paymentinstructions}
                </React.Fragment>
              ) : null}

              <Typography variant="h4" component="h1" gutterBottom style={{ marginTop: 25 }}>
                {t("order.status")}
              </Typography>
              <List component="nav" className={classes.statuslistroot} aria-label="contacts">
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon className={classes.green} />
                  </ListItemIcon>
                  <ListItemText primary={t("order.statusorderregistered")} />
                </ListItem>
                <ListItem>
                  {orderData.paid ? (
                    <React.Fragment>
                      <ListItemIcon>
                        <CheckCircleIcon className={classes.green} />
                      </ListItemIcon>
                      <ListItemText primary={t("order.statuspaymentreceived")} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ListItemIcon>
                        <AccessTimeIcon className={classes.lightgray} />
                      </ListItemIcon>
                      <ListItemText primary={t("order.statuspaymentreceived")} className={classes.lightgray} />
                    </React.Fragment>
                  )}
                </ListItem>
                {orderData.shipped ? (
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleIcon className={classes.green} />
                    </ListItemIcon>
                    <ListItemText primary={t("order.statusshipped") + " (" + orderData.shipped_date + ")"} />
                  </ListItem>
                ) : orderData.shiporpickup === "ship" || orderData.shiporpickup === "shiptracked" ? (
                  <ListItem>
                    <ListItemIcon>
                      <AccessTimeIcon className={classes.lightgray} />
                    </ListItemIcon>
                    <ListItemText primary={t("order.statusshipped")} className={classes.lightgray} />
                  </ListItem>
                ) : null}
                {orderData.shipped && orderData.trackingnumber ? (
                  <ListItem button component="a" href={orderData.trackinglink} target="_blank">
                    <ListItemText inset primary={t("order.trackingnumber") + ": " + orderData.trackingnumber} />
                  </ListItem>
                ) : null}
                {orderData.shiporpickup === "pickup" ? (
                  orderData.pickedup ? (
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon className={classes.green} />
                      </ListItemIcon>
                      <ListItemText primary={t("order.statuspickedup")} />
                    </ListItem>
                  ) : (
                    <ListItem>
                      <ListItemIcon>
                        <AccessTimeIcon className={classes.lightgray} />
                      </ListItemIcon>
                      <ListItemText primary={t("order.statuspickedup")} className={classes.lightgray} />
                    </ListItem>
                  )
                ) : null}
              </List>
            </React.Fragment>
          ) : null}
        </Paper>
      </Container>
      <Footer />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorSnackBarOpen}
        onClose={e => {
          setErrorSnackBarOpen(false);
        }}
      >
        <SnackbarContent
          className={classes.error}
          onClose={e => {
            setErrorSnackBarOpen(false);
          }}
          aria-describedby="errorsb"
          message={
            <span id="errorsb" className={classes.message}>
              <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
              {errorSnackBarMessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={e => {
                setErrorSnackBarOpen(false);
              }}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    </React.Fragment>
  );
}
