import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import i18nEN from "./locales/en/translation.json";
import i18nNL from "./locales/nl/translation.json";
import i18nFR from "./locales/fr/translation.json";

i18n
  .use(LanguageDetector)
  .use(XHR)
  .init({
    debug: false,
    lang: "nl",
    fallbackLng: "nl",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    load: "languageOnly",
    whitelist: ["nl", "fr", "en"],
    resources: {
      en: {
        translations: i18nEN
      },
      nl: {
        translations: i18nNL
      },
      fr: {
        translations: i18nFR
      }
    },
    react: {
      wait: true
    },
    ns: ["translations"],
    defaultNS: "translations",
    detection: {
      order: ["path", "cookie", "navigator"],
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,

      caches: ["cookie"],
      excludeCacheFor: ["cimode"],
      checkWhitelist: true
    }
  });

export default i18n;
