import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Home from "./components/pages/Home.jsx";
import OrderTheBook from "./components/pages/OrderTheBook.jsx";
import Order from "./components/pages/Order.jsx";
import PreorderTheBook from "./components/pages/PreorderTheBook.jsx";
import BrowseTheBook from "./components/pages/BrowseTheBook.jsx";
import CulturalHeritage from "./components/pages/CulturalHeritage.jsx";
import HorseGame from "./components/pages/HorseGame.jsx";
import War from "./components/pages/War.jsx";
import Itegem from "./components/pages/Itegem.jsx";
import GreatBritain from "./components/pages/GreatBritain.jsx";
import History from "./components/pages/History.jsx";
import CircusLocations from "./components/pages/CircusLocations.jsx";
import Links from "./components/pages/Links.jsx";
import Images from "./components/pages/Images.jsx";
import ScrollToTop from "./components/various/ScrollToTop.jsx";
import PrivacyPolicy from "./components/pages/PrivacyPolicy.jsx";

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/:lang(nl|fr|en)?/order/:orderhash([a-zA-Z0-9]{64})/:orderid([0-9]+)">
            <Order />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(circus-locations|standplaatsen|emplacements)">
            <CircusLocations />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(history|geschiedenis|histoire)">
            <History />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(horse-game|paardenspel|le-jeu-aux-chevaux)">
            <HorseGame />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(links-with-other-circuses|band-met-andere-circussen|lien-avec-dautres-cirques)">
            <Links />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(cultural-heritage|cultureel-erfgoed|patrimoine-culturel)">
            <CulturalHeritage />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(war|oorlog|guerre)">
            <War />
          </Route>
          <Route path="/:lang(nl|fr|en)?/itegem">
            <Itegem />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(great-britain|groot-brittanie|grande-bretagne)">
            <GreatBritain />
          </Route>
          <Route
            path="/:lang(nl|fr|en)?/forum"
            component={() => {
              window.location.replace("https://forum.circuskean.com");
              return null;
            }}
          />
          <Route path="/:lang(nl|fr|en)?/(pictures|beelden|images)">
            <Images />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(order-the-book|bestel-het-boek|commander-le-livre)">
            <OrderTheBook />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(preorder-the-book|reserveer-het-boek)">
            <Redirect to="/" />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(browse-the-book|doorblader-het-boek|parcourir-le-livre)">
            <BrowseTheBook />
          </Route>
          <Route path="/:lang(nl|fr|en)?/(privacypolicy)">
            <PrivacyPolicy />
          </Route>
          <Route path="/:lang(nl|fr|en)?/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
