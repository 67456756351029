import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Container, Typography, Paper, Button } from "@material-ui/core";
import { Header, Footer } from "../various";

const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(5) },
  backbtn: {
    marginTop: "40px;"
  }
}));

export default function CulturalHeritage() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Header />
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h1" gutterBottom>
            {t("home.topic.title.culturalheritage")}
          </Typography>
          {t("culturalheritage.text")
            .split("\n")
            .map(text => {
              return <Typography gutterBottom>{text}</Typography>;
            })}

          <Link to="/">
            <Button className={classes.backbtn} variant="contained">
              &larr; {t("global.goback")}
            </Button>
          </Link>
        </Paper>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
