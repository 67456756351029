import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Container, Typography, Paper, Button } from "@material-ui/core";
import { Header, Footer } from "../various";

const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(5) },
  backbtn: {
    marginTop: "40px;"
  }
}));

export default function PrivacyPolicy() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Header />
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h1" gutterBottom>
            Privacy policy for circuskean.com
          </Typography>
          <Typography gutterBottom>
            At circuskean.com, one of our main priorities is the privacy of our
            visitors. This Privacy Policy document contains types of information
            that is collected and recorded by circuskean.com and how we use it.
          </Typography>
          <Typography gutterBottom>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us through email at
            info@circuskean.com
          </Typography>
          <Typography gutterBottom variant="h5">
            Log files
          </Typography>
          <Typography gutterBottom>
            Circuskean.com follows a standard procedure of using log files.
            These files log visitors when they visit websites. All hosting
            companies do this and a part of hosting services' analytics. The
            information collected by log files include internet protocol (IP)
            addresses, browser type, date and time stamp, referring/exit pages.
            These are not linked to any information that is personally
            identifiable. The purpose of the information is for administering
            the site.
          </Typography>
          <Typography gutterBottom variant="h5">
            Cookies
          </Typography>
          <Typography gutterBottom>
            Like any other website, circuskean.com uses ‘cookies'. These cookies
            are used to store information including visitors' preferences (such
            as the language displayed on the website). The information is used
            to optimize the users' experience by customizing our web page
            content based on visitors' browser type and/or other information.
          </Typography>
          <Typography gutterBottom variant="h5">
            Consent
          </Typography>
          <Typography gutterBottom>
            By using our website, you hereby consent to our Privacy Policy.
          </Typography>
          <Link to="/">
            <Button className={classes.backbtn} variant="contained">
              &larr; {t("global.goback")}
            </Button>
          </Link>
        </Paper>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
